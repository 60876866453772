<template>
<div class="container-fluid">
    <div class="row">
        <sidebar></sidebar>
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
            <div class="row">
                <div class="col-12 mb-5">
                    <app-header></app-header>
                    <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                </div>
            </div>
            <!-- Program-fee -->
            <section class="px-0 mx-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
                <div class="container-fluid ">
                    <div class="row justify-content-start mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">
                        <div class="col-12 px-0 px-sm-3">
                          <div v-if="general">
                          
                              <span class="badge badge-primary badge-payment">
                                {{ $t('financial-info.program-price') }} - {{ parseInt(general.fee).toFixed(2) }} {{ getCurrencySymbol(general.currency) }}<br><br>
                                {{ $t('financial-info.grant-gov') }} - {{parseInt(general.grant_gov).toFixed(2) }} {{ getCurrencySymbol(general.currency) }}<br><br>
                                {{ $t('financial-info.social-grant') }} - {{ parseInt(general.grant_social).toFixed(2) }} {{ getCurrencySymbol(general.currency) }}
                              
                              
                              
                              </span>
                          </div>
                          <sk-list v-else :height="0.04" :items-count="1"></sk-list>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                  <div class="container-fluid">

                    <div class="row justify-content-center">
                      <div class="col-6">
                                     <p class="text-center h2 payments_footer bg-light-red">   {{ financial_info_by_kikabidze.length>0?financial_info_by_kikabidze[0].text:'' }}</p>
                     </div>
                    </div>
                  </div>


            </section>

            <!-- Payments section -->
            <section class="paymnets-section  px-0 mx-0 px-xl-5 mx-xl-5 mt-lg-2 mb-3">
                <div class="container-fluid  h-100">
                    <div class="row align-items-center mt-5 h-100">

                        <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mb-4 mb-5 pb-5 px-0 px-sm-3">
                            <div class="row no-gutters justify-content-center mb-5 pb-5">


                                <div class="col-9 col-md-8 col-lg-12 text-center mt-3 mb-5">
                                    <span class="f-h-bold">
                                        <!-- {{ $t('financial-info.note') }} -->
                                    </span>
                                </div>

                                <!-- All payments -->
                                <div class="col-12 mb-5 mb-sm-4">
                                  <div v-if="general" class="payments__container bg-light b-30 shadow-1">
                                      <div class="payments_header bg-light-blue">
                                          <div class="flex2">&nbsp;</div>
                                          <div class="flex1"><span class="f-h-h">{{ $t('financial-info.debit') }}</span></div>
                                          <div class="flex1"><span class="f-h-h">{{ $t('financial-info.credit') }}</span></div>
                                      </div>
<!--                                      <div class="payments_body bg-light-blue">-->
<!--                                          <div class="payments_entry">-->
<!--                                            <div class="flex2"><span>{{ $t('financial-info.starting-balance') }}</span></div>-->
<!--                                            <div class="flex1"><span>{{ parseInt(general.debit).toFixed(2) }}</span></div>-->
<!--                                            <div class="flex1"><span>{{ parseInt(general.credit).toFixed(2) }}</span></div>-->
<!--                                          </div>-->
<!--                                          <div class="payments_entry">-->
<!--                                            <div class="flex2"><span>{{ $t('financial-info.current-fee') }}</span></div>-->
<!--                                            <div class="flex1"><span> </span></div>-->
<!--                                            <div class="flex1"><span>{{ parseInt(general.fee_new_gel).toFixed(2) }} {{ $t('financial-info.gel') }}</span></div>-->
<!--                                          </div>-->
<!--                                          <div class="payments_entry">-->
<!--                                              <div class="flex2"><span>{{ $t('financial-info.government-grant') }}</span></div>-->
<!--                                              <div class="flex1"><span>{{ parseInt(general.grant_gov).toFixed(2) }}</span></div>-->
<!--                                              <div class="flex1"><span>&nbsp;</span></div>-->
<!--                                          </div>-->
<!--                                          <div class="payments_entry">-->
<!--                                              <div class="flex2"><span>{{ $t('financial-info.social-grant') }}</span></div>-->
<!--                                              <div class="flex1"><span>{{ parseInt(general.grant_social).toFixed(2) }}</span></div>-->
<!--                                              <div class="flex1"><span>&nbsp;</span></div>-->
<!--                                          </div>-->
<!--                                          <div class="payments_entry">-->
<!--                                              <div class="flex2"><span>{{ $t('financial-info.university-grant') }}</span></div>-->
<!--                                              <div class="flex1"><span>{{ parseInt(general.uni_grant).toFixed(2) }}</span></div>-->
<!--                                              <div class="flex1"><span>&nbsp;</span></div>-->
<!--                                          </div>-->
<!--                                          <div class="payments_entry">-->
<!--                                              <div class="flex2"><span>{{ $t('financial-info.paid') }}</span></div>-->
<!--                                              <div class="flex1"><span>{{ parseInt(general.sum_amount).toFixed(2) }}</span></div>-->
<!--                                              <div class="flex1"><span>&nbsp;</span></div>-->
<!--                                          </div>-->
<!--&lt;!&ndash;                                            <div class="payments_entry">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="flex2"><span>{{ $t('financial-info.total-to-pay') }}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="flex1"><span>667</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="flex1"><span>&nbsp;</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--                                      </div>-->


                                       
                                    <div v-if="parseInt(general.is_calc) && dontShow==0" class="payments_footer bg-light-red">
                                      <div class="flex2"><span class="f-h-h">{{ $t('financial-info.balance') }}</span></div>
                                      <div class="flex1">&nbsp;</div>

                                       

                                 
                                      <div v-if="general.final_amount < 0" class="flex1"><span style="position: relative;right: 100%;" class="f-h-h">{{ parseFloat(general.final_amount).toFixed(2) }} {{ getCurrencySymbol(general.currency) }}</span></div>
                                      <div v-else class="flex1 text-success">
                                        
                                        <span v-if="general.final_amount" class="f-h-h">{{ parseFloat(general.final_amount).toFixed(2) }} {{ getCurrencySymbol(general.currency) }}</span>
                                      </div>
                                    </div>
                                    <div v-else class="payments_footer bg-light-red">
                                      <div class="flex1"><span class="f-h-h">{{ $t('financial-info.coming-soon') }}</span></div>
                                    </div>
                                  </div>
                                  <sk-list v-else :height="0.05" :items-count="7"></sk-list>
                                </div>

                                <!-- details -->
                                <div class="col-12 mt-3 mt-sm-0 px-5 mb-5">
                                    <!-- Payment details -->
                                    <div class="accordion" id="payments">
                                        <!-- Current semester -->
                                        <div class="card current py-2">
                                            <div class="card-header " id="paymentsHeading">
                                                <h2 class="mb-0 p-0">
                                                    <button @click="loadDetails" class="btn btn-registration" type="button" data-toggle="collapse" data-target="#paymentsDetails" aria-expanded="false" aria-controls="paymentsDetails">
                                                        {{ $t('financial-info.see-payment-details') }}
                                                        <span class="toggle-icon"></span>
                                                    </button>
                                                </h2>
                                            </div>

                                            <!-- payments table -->
                                            <div id="paymentsDetails" class="collapse" aria-labelledby="paymentsHeading" data-parent="#payments">
                                                <div class="card-body px-2 py-3">
                                                  <table v-if="loading" class="table table-blue">
                                                    <thead>
                                                    <tr>
                                                      <td></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                      <td>
                                                        <sk-list :height="0.04" :items-count="6"></sk-list>
                                                      </td>
                                                    </tr>
                                                    </tbody>
                                                  </table>
                                                  <table v-else class="table table-blue">
                                                      <thead>
                                                          <tr>
                                                              <th>{{ $t('financial-info.payment-date') }}</th>
                                                              <th>{{ $t('financial-info.payment-service') }}</th>
                                                              <th style="width: 16%">{{ $t('financial-info.amount') }}</th>
                                                              <th>{{ $t('financial-info.payment-code') }}</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr v-for="detail of details">
                                                          <td>{{ detail.tarighi }}</td>
                                                          <td>{{ helpers.getLocalizedField(detail, 'types') }}</td>
                                                          <td>{{ parseInt(detail.amount).toFixed(0) }} <span v-if="general">{{  getCurrencySymbol(general.currency) }}</span></td>
                                                          <td>{{ detail.payID }}</td>
                                                        </tr>
                                                      </tbody>
                                                  </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>










                                    <div class="accordion" id="scholarship">
                                        <!-- Current semester -->
                                        <div class="card current py-2">
                                            <div class="card-header " id="scholarshipHeading">
                                                <h2 class="mb-0 p-0">
                                                    <button @click="loadDetails" class="btn btn-registration" type="button" data-toggle="collapse" data-target="#scholarshipDetails" aria-expanded="false" aria-controls="scholarshipDetails">
                                                        {{ $t('financial-info.scholarship') }}
                                                        <span class="toggle-icon"></span>
                                                    </button>
                                                </h2>
                                            </div>

                                            <!-- scholarship table -->
                                            <div id="scholarshipDetails" class="collapse" aria-labelledby="scholarshipHeading" data-parent="#scholarship" v-if="scholarShip">
                                                <div class="card-body px-2 py-3">
                                                  <table v-if="loading" class="table table-blue">
                                                    <thead>
                                                    <tr>
                                                      <td></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                      <td>
                                                        <sk-list :height="0.04" :items-count="6"></sk-list>
                                                      </td>
                                                    </tr>
                                                    </tbody>
                                                  </table>
                                                  <table v-else class="table table-blue">
                                                      <thead>
                                                          <tr>
                                                              <th>{{ $t('financial-info.payment-date') }}</th>
                                                              <th>{{ $t('financial-info.scholarship') }}</th>
                                                               
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr v-for="detail of scholarShip">
                                                          <td>{{ detail.transfer_date }}</td>
                                                          <td>{{ detail.stipendia  }} ₾</td> 
                                                        </tr>
                                                      </tbody>
                                                  </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>









                                


                                    <!-- Payment details end -->
                                </div>
                                <!-- form -->
<!--                                <div class="col-10 col-sm-8 col-md-8 col-lg-8 col-xl-7 px-4 mb-5 pb-5" >-->
<!--                                    <div class="form-container mx-2">-->
<!--                                        <div class="form-header">-->
<!--                                            <span>სწავლის საფასურის გადახდა Online</span>-->
<!--                                        </div>-->
<!--                                        <div class="form-body">-->
<!--                                            <form>-->
<!--                                                <div class="form-group mb-4">-->
<!--                                                    <label class="sr-only" for="forAmount">Amount</label>-->
<!--                                                    <input type="text" id="forAmount" name="amount" placeholder="თანხა" class="form-control">-->
<!--                                                </div>-->

<!--                                                <div class="form-group pb-4 pb-sm-0 mb-5">-->
<!--                                                    <label class="sr-only" for="forService">Payment</label>-->
<!--                                                    <select id="forService" >-->
<!--                                                        <option>აირჩიეთ გადახდის ტიპი</option>-->
<!--                                                        <option>სწავლის საფასური</option>-->
<!--                                                        <option>გამოცდის აღდგენის საფასური</option>-->
<!--                                                        <option>მოკლე ვადიანი კურსი</option>-->
<!--                                                        <option>წიგნების მაღაზია</option>-->
<!--                                                        <option>ოლიმპიადები და კონფერენციები</option>-->
<!--                                                        <option>სხვა</option>-->
<!--                                                    </select>-->
<!--                                                </div>-->

<!--                                                <input type="submit" value="გადახდა" class="btn btn-primary-red-7" />-->
<!--                                            </form>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    </div>
</div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import FinancialInfoResource from '../api/financialInfoResource';
import SkList from '../components/skeletons/sk-list';

const financialInfoResource = new FinancialInfoResource();

export default {
    name:'financial-info',
    components: { SkList, sidebar, appHeader, pageHeader },
    computed: {
      pageData() {
        return {
          title: this.$t('financial-info.title'),
            breadcrumb: [
              {
                icon: '',
                title: this.$t('home.title'),
                link: '/',
                isActive: false,
              },
              {
                icon: '',
                title: this.$t('financial-info.title'),
                link: '/financial-info',
                isActive: true,
              }
          ],
        };
      },
    },

    data() {
      return {
        general: null,
        details: [],
        loading: false,
        dontShow:0,
        financial_info_by_kikabidze:'',
        scholarShip:[]
      }
    },

    mounted() {
        this.loadGeneralData();


        financialInfoResource.finance_temp().then(response=>{


          this.dontShow=response.data

          

        })



        financialInfoResource.financial_info_by_kikabidze().then(response=>{

 

              this.financial_info_by_kikabidze=response.data


        })



        financialInfoResource.getScholarship().then(response=>{

          this.scholarShip=response.data
        })

    },

  methods: {
      loadGeneralData() {
        financialInfoResource.generalData().then(response => {
          this.general = response.data.data;
        })
      },

      getCurrencySymbol(currency) {
        return currency == 'GEL' ? this.$t('financial-info.gel') : this.$t('financial-info.usd');
      },

      loadDetails() {
        this.loading = true;
        financialInfoResource.details().then(response => {
          this.details = response.data.data;
        }).finally(() => {
          this.loading = false;
        })
      }
    }
}
</script>
